
.wrapper {
  height: 100vh;
  width: 100vw;
}

.media-container {
  width: 100vw; /* Could also use width: 100%; */
  height: 100vh;
  position: absolute; /* Change position to absolute if you don't want it to take up the whole page */
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  background-color: black;
  z-index: -9999;
}

.media-container video, .media-container img {
  /* Make video to at most 100% wide and tall */
  max-width: 100%; 
  max-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.game-over-text {
  width: 50%;
  position: absolute;
  top: 20%;
  font-size: 48px;
  font-weight: bold;
  color: red;
  text-align: center;
  margin: 25%;
}

.you-win-score {
  margin-bottom: 1vh;
  font-size: 3vh;
  font-weight: bold;
  color: red;
}
