* {
  box-sizing: border-box;
}

/* .contents {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 5px;
} */
