.question {
  max-height: 100%;
  display: flex;
  flex-direction: column;;
  align-items: center;
}

.question-container {
  display: flex;
  flex-direction: column;
}

.answers {
  margin-bottom: 4%;
}

.spaceship, .alien, .boss, .timer-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spaceship > img, .alien > img, .boss > img {
  width: 20vh;
  height: auto;
}

.boss {
  margin-bottom: 5vh;
}

.timer-bar {
  margin-bottom: 2vh;
}

@media screen and (min-width: 500px) {
  .question-container {
    width: 500px;
    display: flex;
    flex-direction: column;
  }

  .timer-bar {
    margin-bottom: 5vh;
  }
}
