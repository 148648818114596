.navbar-sound-button {
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width: 10vw;
  border: 1px solid #fff;
  border-radius: 5px;

  cursor: pointer;
  background-color: transparent;

  text-align: center;
  font-size: 1rem;
  color: #fff;
}

.intro-sound-button {
  position: absolute;
  top: 80vh;
  right: 0;
  height: 6vh;
  width: 6vh;

  transform: translate(-10%,-50%);
  border: 3px solid #fff;
  border-radius: 5px;

  cursor: pointer;
  background-color: #9be3e6;

  text-align: center;
  font-size: 2vh;
  color: #fff;
}

@media screen and (min-width: 500px) {
  .navbar-sound-button {
    width: 50px;
  }
}

@media screen and (min-width: 800px) {
  .intro-sound-button {
    right: 30vw;
  }
}
