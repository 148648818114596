
.restart-btn {
  padding: 1vh;
    
  border: 0.5vh solid #000;
  border-radius: 5px;

  cursor: pointer;
  background-color: #fa4616;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}