.spaceship-one {
  background-color: #000;
  padding: 5px;
  border-radius: 5px;
  padding: 5px;
}


.spaceship-one-link {
  display: flex;
  align-items: center;

  font-family: Gotham, sans-serif;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.spaceship-one-link > img {
  width: 5vh;
}

.spaceship-one-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 2vh;
}


.spaceship-one-intro {
  position: absolute;
  top: 90vh;
  left: 50vw;

  transform: translate(-50%,-50%);
}
