.progress-bar {
  position: relative;
  height: 10px;
  width: 350px;
  border-radius: 50px;
  border: 1px solid #333;
}

.filler {
  background: rgb(230, 45, 39);
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}