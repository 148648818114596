
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 6vh;
  width: 100vw;

  margin-bottom: 1vh;
  padding-left: 5vw;
  padding-right: 5vw;
  border-bottom: 1px solid #000;

  background-color: #fa4616;
  color: #fff;
}

.navbar-score {
  font-family: Gotham;
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (min-width: 500px) {
  .navbar {
    width: 554px;
  }
}
