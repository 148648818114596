
.name-entry {
  width: 80%;
  height: 75px;
  position: absolute;
  bottom: 8%;
  margin: 0 10% 0 10%;
}

.name-entry-label {
  display: block;
  text-align: center;
  font-size: 1.6em;
  font-weight: bold;
  color: #fa4616;
  padding-bottom: .5em;
}

.name-entry input {
  width: 75%;
  height: 40px;
  background-color: #ffffff;
  font-size: 1.2em;
  color: black;
  border: 3px solid black;
  border-radius: 5px;   
}

.name-entry-btn {
  width: 24%;
  height: 40px;
  text-align: center;
  background-color: #00ffff;
  color: black;  
  font-size: 1.2em;
  font-weight: bold;
  padding: 2px;
  border: 3px solid black;
  border-radius: 5px;
  margin-left: 1%;
}

/* Mobile in Landscape Mode; Tablets */
@media screen and (min-width: 500px) {
  .name-entry {
    width: 50%;
    margin: 0 25% 0 25%;
    bottom: 5%;
  }
}

/* XL Phones */
@media screen and (min-width: 500px) and (max-height: 450px) {
  .name-entry {
    width: 50%;
    max-width: 500px;
    margin: 0 25% 0 25%;
    bottom: 5%;
  }
}

/* Laptop, Desktop, Responsively-sized Windows  */
@media screen and (min-width: 800px) and (min-height: 450px) {
  .name-entry {
    width: 30%;
    max-width: 800px;
    margin: 0 35% 0 35%;
    bottom: 8%;
  }
  .name-entry-label {
    font-size: 2em;
  }
}
