.share-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;


  margin-bottom: 2vh;
  padding: 2vw;

  color: #fff;
  background-color: #000;
}
