.question-box, .answer-box, .hp-box, .enemy-hp-box {
  background-color: #fa4616;
  border: 0.5vh solid #000;
  border-radius: 10px;

  color: #fff;

  text-align: center;
}


.question-box {
  padding: 2vh;
  margin-bottom: 2vh;
  font-weight: 600;
  font-size: 1.2rem ;
}

.question-box > p {
  margin: 0;
}

.answer-box {
  margin-bottom: 1vh;
  font-weight: 600;
}

.answer-box:hover {
  cursor: pointer;
}

.answer-box > p {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.hp-box, .enemy-hp-box {
  width: 75px;
  padding: 5px;
  border-width: 0.5vh;
}

.hp-box {
  background-color: #1638fa;
}

@media screen and (min-width: 500px) {
  .question-box {
    padding: 3vh;
  }

  .answer-box > p {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}
