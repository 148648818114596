
.skip-btn {
  position: absolute;
  bottom: 8%;
  width: 20%;
  margin: 0 40%;

  background-color: #00ffff;
  text-align: center;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  padding: 2px;
  border: 3px solid black;
  border-radius: 5px;  
}

.skip-btn:hover, .name-entry-btn:hover {
  background-color: #b3ffff;
  border-color: #b3b3cc; 
}
.skip-btn:active, .name-entry-btn:active {
  background-color: #00b3b3;
  border-color: #47476b;  
}

/* Mobile Landscape | Tablets */
@media all and (min-width: 500px) {
  .skip-btn {
    bottom: 6%;
    width: 14%;
    margin: 0 44%;
  }
}

/* Laptop, Desktop */
@media screen and (min-width: 800px) {
  .skip-btn {
    bottom: 8%;
    width: 8%;
    max-width: 200px;
    margin: 0 46%;
    font-size: 2em;
  }
}
